import React from "react"

const Footer = () => (
  <p>
    © {new Date().getFullYear()}, Daniel Rangel
    {` `}
  </p>
)

export default Footer
